<template>
  <v-container>
    <h1>
      <p class="text-center">BCRM</p>
    </h1>
    <h3>
      <p class="text-center">
        NT PLC Central Region Telecommunication Engineering Sector
      </p>
    </h3>
    <h5>
      <p class="text-center">User ID : {{ this.userId }}</p>
      <p class="text-center">Access Token : {{ this.accessToken }}</p>
      <p class="text-center">ID Token : {{ this.idToken }}</p>
      <p class="text-center">Scan Code Result : {{ this.scanCodeResult }}</p>
      <v-btn small block @click="scanCode">
        <v-icon left> mdi-qrcode-scan </v-icon>
        Scan QR
      </v-btn>
    </h5>
  </v-container>
</template>

<script>
import liff from "@line/liff";

export default {
  name: "Contact",
  props: {
    // msg: String,
  },
  data: () => ({
    userId: null,
    email: null,
    accessToken: null,
    idToken: null,
    scanCodeResult: null,
  }),
  beforeCreate() {
    console.log(process.env.VUE_APP_LIFF_ID)
    liff.init({ liffId: `${process.env.VUE_APP_LIFF_ID}` }, () => {
      if (liff.isLoggedIn()) {
        this.runApp();
      } else {
        liff.login();
      }
    });
  },
  methods: {
    runApp() {
      this.accessToken = liff.getAccessToken();
      this.idToken = liff.getIDToken();
      liff
        .getProfile()
        .then((profile) => {
          console.log(profile);
          this.pictureUrl = profile.pictureUrl;
          this.userId = profile.userId;
          this.displayName = profile.displayName;
          this.statusMessage = profile.statusMessage;
          // this.email = liff.getDecodedIDToken().email;
        })
        .catch((err) => console.error(err));
    },
    scanCode() {
      liff
        .scanCodeV2()
        .then((result) => {
          // e.g. result = { value: 'Hello LIFF app!' }
          this.scanCodeResult = result.value;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
